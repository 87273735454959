import { NextPage } from "next";

import SignInPage from "@/pages/auth";

const Index: NextPage = () => <SignInPage />;

export default Index;

export const getServerSideProps = async () => ({
  props: { noHeader: true },
});
